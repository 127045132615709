import React from "react";
import MonitorImage from "../assets/img/monitor.jpg";
import ProgramImage from "../assets/img/program.jpg";
import TestImage from "../assets/img/Test.jpg";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Container,
  Grid,
} from "@mui/material";

const Methods = () => {
  const ServicesData = [
    {
      title: "1. Test",
      description: `
        &bull; <b>Comprehensive Evaluation</b>: We start with a thorough fitness and well-being assessment.<br />
        &bull; <b>Set the Baseline</b>: Understand where you are now to tailor your training program effectively.
      `,
      imageUrl: TestImage,
    },
    {
      title: "2. Program",
      description: `&bull; <b>Customized Plans</b>: Receive a workout and diet plan designed just for you.<br />
        &bull; <b>Science-Based</b>: All programs are grounded in the latest research and my extensive experience.`,
      imageUrl: ProgramImage,
    },
    {
      title: "3. Monitor and Adjust",
      description: `&bull; <b>Continuous Tracking</b>: Regular check-ups to keep an eye on your progress. <br />
        &bull; <b>Flexible Adjustments</b>: We tweak your plan as needed to ensure you keep improving and stay on track.`,
      imageUrl: MonitorImage,
    },
  ];

  return (
    <>
      <Container sx={{ py: 8 }}>
        <Grid container spacing={2} justifyContent="center">
          {ServicesData.map((service, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <CardMedia
                  //sx={{ height: 0, paddingTop: '56.25%' }} // 16:9 aspect ratio
                  sx={{ height: 250 }}
                  image={service.imageUrl}
                  title={service.title}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="div">
                    {service.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    dangerouslySetInnerHTML={{ __html: service.description }}
                  ></Typography>
                </CardContent>
                {/* <CardActions sx={{ mt: 'auto', justifyContent: 'flex-end' }}>
                  <Button size="small">Learn More</Button>
                </CardActions> */}
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default Methods;
