import React from "react";
// import { MdKeyboardArrowRight } from "react-icons/md";
import profileImage from "../assets/img/moe.jpg"; // Import the image file
import Social from "./social";
// import {Link} from 'react-scroll'

const about = () => {
  return (
    <div className="h-1/2 w-full bg-gradient-to-b from-custom-black via-custom-gray to-custom-black text-white pb-2">
      <div
        className="max-w-screen-lg mx-auto flex flex-col items-center justify-center px-4 md:flex-row
      p-0 m-0 "
      >
        {/* <div className='absolute top--3/4 left-0 md:hidden lg:hidden xl:block 2xl:block sm:block'> <Social /> </div> */}

        <div className="flex flex-col justify-center h-1/2 mb-1 w-full mr-4">
          {/* <h2 className="text-xl sm:text-xl font-bold font-title mb-0 text-center">
            {" "}
            BAZZ ATHLETE{" "}
          </h2> */}
          <p className="py-4 pb-0 mb-2 w-100 md:mt-8 pt-1 text-justify">
            Mohammad Bazzi is a dedicated Sport Scientist/ Athletic Trainer with
            a background in Physical Education BSc, Sport Management MA, and
            High Performance Sport MSc. Fluent in multiple languages, he
            connects easily with diverse athletes. Currently working at SC Bayer
            05 Uerdingen and teaching in Krefeld, Mohammad brings hands-on
            experience from roles including Research Associate at Liverpool John
            Moores University and Athletic Trainer at FC Saarbruecken. With a
            passion for creating individualized training programs and fostering
            a supportive environment, Mohammad is committed to helping athletes
            reach their full potential. Join him to push your limits and achieve
            greatness together!
          </p>

          <div className="flex justify-center items-center w-full">
            <div>
              <Social />
            </div>
          </div>
        </div>

        <div className="m-1 p-0 h-1/2 mt-2">
          <img
            src={profileImage}
            alt="profile"
            className="rounded-2xl mx-auto md:w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default about;
