import NavBar from "./components/navbar";
import Slide from "./components/slide";
import About from "./components/about";
// import Video from "./components/video";
import Services from "./components/services";
import Contact from "./components/contact";
import Footer from "./components/footer";
import Reviews from "./components/reviews";
import Methods from "./components/methods";

function App() {
  return (
    <div name="Home">
      <NavBar />

      <div style={{ paddingTop: "5rem" }}>
        <Slide />
      </div>

      <div>
        <h1 className="center-bold-gold" name="About">
          MEET OUR COACH
        </h1>
      </div>

      <About />

      <div>
        <h1 className="center-bold-gold" name="Methods">
          Our Methods
        </h1>
      </div>

      <div className="background m-0">
        <Methods />
      </div>

      <div>
        <h1 className="center-bold-gold" name="Services">
          Our Services
        </h1>
      </div>

      <div className="background m-0">
        <Services />
      </div>

      {/* <div>
        <h1 className="center-bold-gold" name="Methods">
          Our Methods
        </h1>
      </div>

      <div className="background m-0">
        <Methods />
      </div> */}

      <div>
        <h1 className="center-bold-gold" name="Reviews">
          Reviews
        </h1>
      </div>
      <div className="">
        <Reviews />
      </div>

      <div>
        <h1 className="center-bold-gold" name="Contact Us">
          Contact Us
        </h1>
      </div>

      <Contact />

      <Footer />
    </div>
  );
}

export default App;
