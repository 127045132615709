import { useState, useEffect } from "react";

const Reviews = () => {
  const [sliderConf, setSliderConf] = useState({
    sliderPointer: 0,
    data: [],
  });
  // useEffect(() => {
  //   setSliderConf({
  //     ...sliderConf,
  //     data: [
  //       {
  //         description:
  //           "Mohammad Bazzi was not just a coach, but a friend and supporter during my toughest times. He helped me understand sports and my body’s capabilities, building an awareness that many young Arabs lack.",
  //         author: "Nader",
  //       },
  //       {
  //         description:
  //           "After graduating and injuring my ankle, I stopped doing sports and my weight increased from 96 kg to 110 kg. Thanks to the support of Mohammad Bazzi, I decided to regain my fitness. Over 7-8 months, I dropped to 83 kg and lost 27 kg of fat. My fitness improved significantly and I ran 3200 meters in 12:38 minutes instead of 21 minutes. I committed to training, avoided mistakes, and became more active and healthier. Training like athletes is the key to energy and endurance, and shape isn’t the most important thing.",
  //         author: "Mo",
  //       },
  //       {
  //         description:
  //           "Through Bazzi’s 6 weeks training plan, I feel much more robust, energetic, and lighter on the field. My running performance has significantly improved, and I feel very comfortable with the plan. Thanks, Mo.",
  //         author: "David",
  //       },
  //       {
  //         description:
  //           "Before working with Bazz, I used to spend 3 hours in the gym, exhausting myself. Through Bazz, I learned that gym training can be effective with fewer exercises and focused strength. As a result, I feel faster, fitter, and more explosive—all thanks to Bazz!",
  //         author: "Matin",
  //       },
  //     ],
  //   });
  // }, [sliderConf]);

  useEffect(() => {
    setSliderConf((prevConf) => ({
      ...prevConf,
      data: [
        {
          description:
            "Mohammad Bazzi was not just a coach, but a friend and supporter during my toughest times. He helped me understand sports and my body’s capabilities, building an awareness that many young Arabs lack.",
          author: "Nader",
        },
        {
          description:
            "After injuring my ankle and gaining weight, I lost 27 kg in 7-8 months with the support of Mohammad Bazzi, improving my fitness and running 3200 meters in 12:38 minutes. Training like an athlete transformed my health and energy.",
          author: "Mo",
        },
        {
          description:
            "Through Bazzi’s 6 weeks training plan, I feel much more robust, energetic, and lighter on the field. My running performance has significantly improved, and I feel very comfortable with the plan. Thanks, Mo.",
          author: "David",
        },
        {
          description:
            "Before working with Bazz, I used to spend 3 hours in the gym, exhausting myself. Through Bazz, I learned that gym training can be effective with fewer exercises and focused strength. As a result, I feel faster, fitter, and more explosive—all thanks to Bazz!",
          author: "Matin",
        },
      ],
    }));
  }, []);

  const nextSlide = () => {
    let { data, sliderPointer } = sliderConf;
    let currentSliderPointer = sliderPointer + 1;
    if (data.length - 1 === sliderPointer) {
      currentSliderPointer = 0;
    }
    setSliderConf({
      ...sliderConf,
      sliderPointer: currentSliderPointer,
    });
  };
  const prevSlide = () => {
    let { data, sliderPointer } = sliderConf;
    let currentSliderPointer = sliderPointer - 1;
    if (sliderPointer === 0) {
      currentSliderPointer = data.length - 1;
    }
    setSliderConf({
      ...sliderConf,
      sliderPointer: currentSliderPointer,
    });
  };
  const { sliderPointer, data } = sliderConf;
  const currentSlide = data[sliderPointer];
  return (
    <div className="App">
      {currentSlide ? (
        <div className="slideshow-container">
          <div className="mySlides">
            <q>{currentSlide["description"]}</q>
            <p className="author">- {currentSlide["author"]}</p>
          </div>
          <button className="prev" onClick={prevSlide}>
            ❮
          </button>
          <button className="next" onClick={nextSlide}>
            ❯
          </button>
        </div>
      ) : null}

      {/* <div className="dot-container">
        <span className="dot" onclick="currentSlide(1)"></span>
        <span className="dot" onclick="currentSlide(2)"></span>
        <span className="dot" onclick="currentSlide(3)"></span>
      </div> */}
    </div>
  );
};

export default Reviews;
