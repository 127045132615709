import React from "react";

import TrainingImage from "../assets/img/Training guides.jpg";
import OneOnOneImage from "../assets/img/OneOnOne.jpg";
import InPersonImage from "../assets/img/InPerson.jpg";

import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Container,
  Grid,
  CardActions,
  Button,
} from "@mui/material";

const Services = () => {
  const ServicesData = [
    {
      title: "One-on-One Online Training",
      description: `&bull; <b>Packages</b>: 6 or 12 weeks<br/>
        &bull; <b>What You Get</b>: Personalized workout plan, custom diet plan, regular video calls, and 24/7 chat support.<br/>
        &bull; <b>Why It Works</b>: Continuous progress checks and adjustments to keep you on track and motivated.`,
      imageUrl: OneOnOneImage,
    },
    {
      title: "Training Guides",
      description: `&bull; <b>Affordable & Flexible</b>: Choose from a variety of guides tailored to different fitness goals.<br/>
        &bull; <b>Comprehensive</b>: Detailed instructions and tips for building strength, improving endurance, or reducing injury risk.<br/>
        &bull; <b>Self-Paced</b>: Achieve your fitness goals on your own schedule.`,
      imageUrl: TrainingImage,
    },
    {
      title: "In-Person Training",
      description: `&bull; <b>Location</b>: Krefeld, near Düsseldorf<br/>
        &bull; <b>Personalized Programs</b>: Custom workout plans, technique refinement, agility drills, and conditioning exercises, and many more.<br/>
        &bull; <b>For All Levels</b>: Suitable for absolute beginners as well as professionals wanting to gain the 1% edge.
        `,
      imageUrl: InPersonImage,
    },
  ];

  return (
    <>
      <Container sx={{ py: 8 }}>
        <Grid container spacing={2} justifyContent="center">
          {ServicesData.map((service, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <CardMedia
                  //sx={{ height: 0, paddingTop: '56.25%' }} // 16:9 aspect ratio
                  sx={{ height: 250 }}
                  image={service.imageUrl}
                  title={service.title}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="div">
                    {service.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    dangerouslySetInnerHTML={{ __html: service.description }}
                  ></Typography>
                </CardContent>
                {/* <CardActions sx={{ mt: "auto", justifyContent: "flex-end" }}>
                  <Button size="small">Learn More</Button>
                </CardActions> */}
                {service.title === "Training Guides" && (
                  <CardActions sx={{ mt: "auto", justifyContent: "flex-end" }}>
                    <Button
                      size="small"
                      href="https://drive.google.com/drive/folders/1evvBMuo2P4ovjWLZjWTPpLYhkNuqZDFg"
                      target="_blank"
                    >
                      View Guides
                    </Button>
                  </CardActions>
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default Services;
