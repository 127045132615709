import React from "react";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
// import { BsFillPersonLinesFill } from "react-icons/bs";

const SocialLinks = () => {
  const links = [
    {
      id: 1,
      child: (
        <>
          <FaLinkedin size={30} />
        </>
      ),
      href: "https://www.linkedin.com/in/mohammad-bazzi-4151a0134/",
    },
    {
      id: 2,
      child: (
        <>
          <HiOutlineMail size={30} />
        </>
      ),
      href: "mailto:moebaz26@gmail.com",
    },

    {
      id: 3,
      child: (
        <>
          <FaInstagram size={30} />
        </>
      ),
      href: "https://www.instagram.com/bazzathlete/",
    },
  ];

  return (
    <div className="w-50 h-auto">
      {/* animate-pulse  */}
      <ul className="list">
        {links.map(({ id, child, href }) => (
          <li
            key={id}
            className={
              "m-2 p-2 hover:scale-110 bg-gray-500 border-solid rounded-lg w-13 flex animate-pulse"
            }
          >
            <a href={href} className="text-white">
              {" "}
              {child}{" "}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SocialLinks;
